import React from "react";
//import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import { Icon } from "react-icons-kit";
import { playCircle } from "react-icons-kit/fa/playCircle";
import Text from "reusecore/src/elements/Text";
import Image from "reusecore/src/elements/Image";
import Button from "reusecore/src/elements/Button";
import Heading from "reusecore/src/elements/Heading";
import Rating from "common/src/components/Rating";

import Container from "common/src/components/UI/Container";

//import SceneComponent from "components/containers/AppClassic/SceneComponent";
// import SceneComponent from "../SceneComponent";

// import ReactDOM from "react-dom";

import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup
} from "./banner.style";

//import microsoft from 'common/src/assets/image/appClassic/microsoft.svg';
//import bannerImg from "common/src/assets/image/appClassic/device-in-hand.png";
import bannerImg from "common/src/assets/image/appClassic/landscape-phone.png";
//import bannerImg from "common/src/assets/image/appClassic/landscape-phone-2.png";

//import * as THREE from "common/src/scripts/three/Three";

const Banner = () => {
  // var scene = new THREE.Scene();
  // var camera = new THREE.PerspectiveCamera(50, 500 / 400, 0.1, 1000);

  // //var renderer = new THREE.WebGLRenderer();
  // var renderer = new THREE.WebGLRenderer({ canvas: artifactCanvas });
  // renderer.setSize(500, 400);
  // //document.body.appendChild(renderer.domElement);

  // var geometry = new THREE.SphereGeometry(
  //   3,
  //   50,
  //   50,
  //   0,
  //   Math.PI * 2,
  //   0,
  //   Math.PI * 2
  // );
  // var material1 = new THREE.MeshBasicMaterial();
  // var material2 = new THREE.MeshBasicMaterial();
  // var sphere = [
  //   new THREE.Mesh(geometry, material1),
  //   new THREE.Mesh(geometry, material1),
  //   new THREE.Mesh(geometry, material2)
  // ];

  // sphere[0].position.set(1, 1, 1);
  // sphere[1].position.set(-1, -1, -1);

  // scene.add(sphere[0]);
  // scene.add(sphere[1]);
  // scene.add(sphere[2]);

  // camera.position.z = 10;

  // var hex =
  //   "0x" +
  //   "000000".replace(/0/g, function() {
  //     return (~~(Math.random() * 16)).toString(16);
  //   });
  // sphere[0].material.color.setHex(hex);

  // hex =
  //   "0x" +
  //   "000000".replace(/0/g, function() {
  //     return (~~(Math.random() * 16)).toString(16);
  //   });
  // sphere[2].material.color.setHex(hex);

  // var render = function() {
  //   requestAnimationFrame(render);
  //   renderer.render(scene, camera);
  // };

  // render();

  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          {/* <Fade up>
            <RatingInfo>
              <Rating rating={4} />
              4.9 of 5 By <img src={microsoft} alt="Microsoft" />
            </RatingInfo>
          </Fade> */}
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Innovating the 
      Mobile Landscape 
      since 2004"
            />
          </Fade>
          <Fade up delay={200}>
            <Text content="From inventing core features of smart phones to developing award winning games, no project is too large or small." />
          </Fade>
          {/* <Fade up delay={300}>
            <ButtonGroup>
              <Button className="primary" title="Start Free trail" />
              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={playCircle} />}
                iconPosition="left"
                title="Watch Video"
              />
            </ButtonGroup>
          </Fade> */}
        </BannerContent>
        {/* <canvas id="artifactCanvas" myattr="myattr"></canvas> */}
        {/* <SceneComponent /> */}
        {/* <div
          style={{ height: 640, width: 240, backgroundColor: "#ff0000" }}
        ></div> */}
        <BannerImage>
          <Fade up delay={100}>
            <Image src={bannerImg} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
