import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Fade from "react-reveal/Fade";
import ScrollSpyMenu from "common/src/components/ScrollSpyMenu";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Icon } from "react-icons-kit";
import { menu } from "react-icons-kit/feather/menu";
import { x } from "react-icons-kit/feather/x";
import { search } from "react-icons-kit/feather/search";
import Logo from "reusecore/src/elements/UI/Logo";
import Button from "reusecore/src/elements/Button";
import Container from "common/src/components/UI/Container";
import useOnClickOutside from "common/src/hooks/useOnClickOutside";
import NavbarWrapper, { MenuArea, MobileMenu, Search } from "./navbar.style";

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
    }
  `);
  const { logo, navMenu } = data.appClassicJson.navbar;

  const [state, setState] = useState({
    search: "",
    searchToggle: false,
    mobileMenu: false,
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type) => {
    if (type === "search") {
      setState({
        ...state,
        search: "",
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === "menu") {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };

  const handleOnChange = (event) => {
    setState({
      ...state,
      search: event.target.value,
    });
  };

  const handleSearchForm = (event) => {
    event.preventDefault();

    if (state.search !== "") {
      console.log("search data: ", state.search);

      setState({
        ...state,
        search: "",
      });
    } else {
      console.log("Please fill this field.");
    }
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          className="logo"
          href="/"
          logoSrc={logo.publicURL}
          title="PlayMobility"
        />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? "active" : ""}>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          {/* end of main menu */}
          <div style={{ width: 20 }} />

          {/* <Search className="search" ref={searchRef}>
            <form onSubmit={handleSearchForm}>
              <input
                type="text"
                value={state.search}
                placeholder="Enter your keyword"
                onChange={handleOnChange}
              />
            </form>
            <Button
              className="text"
              variant="textButton"
              icon={<Icon icon={state.searchToggle ? x : search} />}
              onClick={() => toggleHandler("search")}
            />
          </Search> */}
          {/* end of search */}

          <AnchorLink href="#contactus" offset={84}>
            <Button className="trail" title="Contact Us" />
          </AnchorLink>

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler("menu")}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? "active" : ""}`}>
        <Container>
          <ScrollSpyMenu
            className="menu"
            menuItems={navMenu}
            offset={-84}
            drawerClose={() => toggleHandler("menu")}
          />
          <AnchorLink href="#contactus" offset={84}>
            <Button title="Contact Us" onClick={() => toggleHandler("menu")} />
          </AnchorLink>
          <br></br>
          <br></br>
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
