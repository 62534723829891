import React from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Input from "reusecore/src/elements/Input";
import Container from "common/src/components/UI/Container";
import Spinner from "react-spinner-material";
import axios from "axios";

import ContactFromWrapper from "./contact.style";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "",
      formEmail: "",
      formSubject: "",
      formMessage: "",
      isBusy: false,
      message: "",
      isSent: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetForm = this.handleResetForm.bind(this);
  }

  handleResetForm(event) {
    event.preventDefault();
    this.setState({
      formName: "",
      formEmail: "",
      formSubject: "",
      formMessage: "",
      isBusy: false,
      message: "",
      isSent: false,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ message: "", isSent: false });
    if (this.state.formName.length < 1) {
      this.setState({ message: "Please enter your name." });
      return;
    }

    if (!this.state.formEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.setState({ message: "Please  enter a valid email address." });
      return;
    }

    if (this.state.formSubject.length > 100) {
      this.setState({
        message: "Your subject should be less than 100 characters.",
      });
      return;
    }

    if (this.state.formMessage.length > 2000) {
      this.setState({
        message: "Your message should be less than 2000 characters.",
      });
      return;
    }

    this.setState({ isBusy: true, message: "" });
    const data = {
      name: this.state.formName,
      email: this.state.formEmail,
      message: this.state.formMessage,
      subject: this.state.formSubject,
    };

    axios
      .post(
        "https://us-central1-playmobility-ad27e.cloudfunctions.net/sendMail",
        data
      )
      .then((res) => {
        this.setState({
          isBusy: false,
          message: "Message sent!",
          isSent: true,
        });
      })
      .catch((error) => {
        this.setState({
          isBusy: false,
          message: "There was a problem sending message, please try again.",
        });
      });
  }

  render() {
    return (
      <ContactFromWrapper>
        <form
          // method="post"
          // action="https://us-central1-womentechfounders.cloudfunctions.net/sendMail"
          onSubmit={this.handleSubmit}
        >
          <div>
            <input
              type="text"
              name="name"
              id="formName"
              value={this.state.formName}
              onChange={(e) => {
                this.setState({ formName: e.target.value });
              }}
              className="inputField"
              placeholder="Name"
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              id="formEmail"
              value={this.state.formEmail}
              onChange={(e) => {
                this.setState({ formEmail: e.target.value });
              }}
              className="inputField"
              placeholder="Email"
            />
          </div>
          <div>
            <input
              type="text"
              name="subject"
              className="inputField"
              value={this.state.formSubject}
              onChange={(e) => {
                this.setState({ formSubject: e.target.value });
              }}
              placeholder="Subject"
            />
          </div>
          <div>
            <textarea
              name="message"
              rows="5"
              placeholder="Message"
              className="inputField"
              value={this.state.formMessage}
              onChange={(e) => {
                this.setState({ formMessage: e.target.value });
              }}
            ></textarea>
          </div>
          <div style={{ color: "red", paddingBottom: 18 }}>
            {this.state.message}
          </div>

          {this.state.isBusy ? (
            <div style={{ paddingBottom: 8 }}>
              <Spinner
                radius={40}
                color={"#ea1b00"}
                stroke={2}
                visible={true}
              />
            </div>
          ) : this.state.isSent ? (
            <Button title="RESET" onClick={this.handleResetForm} />
          ) : (
            <Button
              type="submit"
              value="submit"
              name="submit"
              id="mc-embedded-subscribe"
              title="SEND MESSAGE"
            />
          )}
        </form>
      </ContactFromWrapper>
    );
  }
}

const ContactSection = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  secHeading,
  secText,
  button,
  note,
}) => {
  return (
    <Box {...sectionWrapper} id="contactus">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="CONTACT US" />
          <Heading {...secHeading} content="Interested in working together?" />
        </Box>
        {/* <Box style={{ maxWidth: 600 }}> */}
        <ContactFromWrapper>
          <ContactForm />
        </ContactFromWrapper>
        {/* </Box> */}
      </Container>
    </Box>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: "contact_section",
    as: "section",
    pt: ["0px", "10px", "20px", "80px"],
    pb: ["0px", "0px", "0px", "0px", "80px"],
    bg: "#f9fbfd",
  },
  secTitleWrapper: {
    mb: ["45px", "50px", "60px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: `${2}`,
    letterSpacing: "0.15em",
    fontWeight: `${6}`,
    color: "primary",
    mb: `${3}`,
  },
  secHeading: {
    textAlign: "center",
    fontSize: [`${6}`, `${8}`],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: "center",
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: "button",
    fontSize: `${2}`,
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
    height: `${4}`,
  },
  note: {
    fontSize: ["13px", "14px", "15px", "15px", "15px"],
    color: "#5d646d",
    lineHeight: "1.87",
    textAlign: "center",
  },
};

export default ContactSection;
