import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Text from "reusecore/src/elements/Text";
import Fade from "react-reveal/Fade";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Image from "reusecore/src/elements/Image";
import Container from "common/src/components/UI/Container";
import SectionWrapper, { ContentWrapper } from "./designedAndBuilt.style";

const DesignedAndBuilt = () => {
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        designAndBuilt {
          image {
            publicURL
          }
          title
          description
        }
      }
    }
  `);
  const { image, title, description } = data.appClassicJson.designAndBuilt;

  return (
    <SectionWrapper id="aboutus">
      {/* <SectionHeader>
        <Fade up>
          <Heading as="h5" content="ABOUT US" />
        </Fade>
      </SectionHeader> */}
      <Container>
        <ContentWrapper>
          <div className="image">
            <Image src={image.publicURL} alt="Built Logo" />
          </div>
          <div className="content">
            <Heading content={title} />
            <Text content={description} />

            <Text></Text>
            {/* <Text
              content="We've been on the forefront of location-based augmented reality since 2011. Check out the founder's sister company QuestUpon (Formerly Legend Tracker)."
              {...description}
            /> */}
            <Text
              content="We love the merging of art and technology to push the limits of what is possible, in-turn, making the world a better place."
              content3="One of our greatest passions is the creation of new and innovative entertainment & technology that have a positive impact on the planet."
              content2="One of our greatest passions is the blending of art and imagination with techology."
              {...description}
            />
            <Text
              content="Check out our founder's award winning sister company QuestUpon (formerly Legend Tracker) which pioneered location-based augmented reality about 10 years ago."
              {...description}
            />
            <a href="https://www.questupon.com">
              <Button title="Learn More" />
            </a>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default DesignedAndBuilt;
