import styled from "styled-components";

const ContactFromWrapper = styled.div`
  align-items: stretch;
  width: 490px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }

  .inputField {
    width: 100%;
    height: 100%;
    background-color: #fff;

    font-size: 16px;
    font-weight: 400;
    color: #343d48;
    padding: 5px 15px;
    border-color: #bbb;
    border-radius: 4px;
    outline: none !important;

    border: 1px solid #bbb;

    @media (max-width: 575px) {
      height: 48px;
    }
    margin-bottom: 16px;
    ::-webkit-input-placeholder {
      /* Edge */
      color: #888888;
    }

    :-ms-input-placeholder {
      /* Internet Explorer */
      color: #888888;
    }

    ::placeholder {
      color: #888888;
    }
  }

  input[type="text"]:focus {
    background-color: white;
    border: 1px solid #ea1b00;
  }

  input[type="email"]:focus {
    background-color: white;
    border: 1px solid #ea1b00;
  }

  textarea:focus {
    background-color: white;
    border: 1px solid #ea1b00;
  }

  .email_input {
    flex-grow: 1;
    margin-right: 20px;
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          color: #aeb1b6;
          font-size: 12px;
        }
      }
    }

    input {
      height: 100%;
      background: #fff;
      font-size: 16px;
      font-weight: 400;
      color: #343d48;
      padding: 5px 15px;

      @media (max-width: 575px) {
        height: 48px;
      }
      margin-bottom: 16px;
    }

    label {
      font-size: 14px;
      color: #343d48;
      font-weight: 500;
      padding-left: 10px;
      top: 5px;
    }
  }

  .field-wrapper {
    height: 100%;
  }
`;

export default ContactFromWrapper;
